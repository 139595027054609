import { useCaseLayout } from '../../../shared/user/layout/caseLayout/useCaseLayout';
import { useProgressBarStore } from '../../../../store/hooks/useProgressBarStore';
import { Stages, stagesAcromegaly, stagesCushing } from './CaseDraft.helper';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDraftCaseApi } from '../../../../apiHooks';
import { DiseaseType } from '../../../../interfaces/common/common';
import { generateLink, ViewUserLink } from '../../../../utils';
import { RoleFunction } from '../../../../constants/projectUsers';

export const useCaseDraftVm = () => {
  const navigate = useNavigate();
  const [startViewing, setStartViewing] = useState(false);
  const { type, id } = useParams();
  const { getDraftCaseFirstStage } = useDraftCaseApi();
  const progressBarStore = useProgressBarStore();
  const { currentStage, isDraft } = progressBarStore;

  const isPrev = !id && Stages.ZERO < currentStage;

  const { filter, handleChange } = useCaseLayout();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStage]);

  useEffect(() => {
    if (!id || Stages.ZERO < currentStage) {
      setStartViewing(true);
    }
  }, [id, currentStage]);

  useEffect(() => {
    if (isDraft !== null && !isDraft) {
      navigate(
        generateLink(RoleFunction.ROLE_VIEWING, ViewUserLink.CASE_VIEW, [
          [':type', type as DiseaseType],
          [':id', Number(id)]
        ])
      );
    }
  }, [isDraft, navigate, type, id]);

  useEffect(() => {
    if (progressBarStore.zeroStage === null && id) {
      void getDraftCaseFirstStage(Number(id), type as DiseaseType);
    }
  }, [progressBarStore, getDraftCaseFirstStage, id, type]);

  useEffect(() => {
    return () => progressBarStore.clearStore();
  }, [progressBarStore]);

  const handleReset = () => {
    progressBarStore.resetProgressBar();
  };

  if (isPrev) {
    handleReset();
  }

  let stageConfig =
    type === 'acromegaly'
      ? stagesAcromegaly.find(element => element.stage === (isPrev ? 0 : currentStage))
      : stagesCushing.find(element => element.stage === (isPrev ? 0 : currentStage));

  return {
    component: stageConfig ? stageConfig.component : null,
    name: stageConfig ? stageConfig.name : '',
    stage: stageConfig ? stageConfig.stage : Stages.ZERO,
    isTitle: stageConfig ? stageConfig.isTitle : false,
    filter,
    handleChange,
    handleReset,
    startViewing
  };
};
