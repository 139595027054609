import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useCaseStore } from '../../../../../../../store/hooks';
import { CheckedIconContainer } from '../../../checkedIcon/CheckedIcon';
import { Text } from '../../../Description.styled';
import { ISecondStageValues } from '../../../../../user/caseDraft/stages/acromegaly/secoundStage/SecondStage.types';
import { Input } from '../../../../../../shared/ui/form/input/Input';
import { getDate } from '../../../../../../../utils/dateSort';
import { observer } from 'mobx-react-lite';

export const SecondStage = observer(() => {
  const caseStore = useCaseStore();
  const { secondStage } = caseStore;

  const secondStageAcromegaly = secondStage as ISecondStageValues;

  if (secondStageAcromegaly) {
    const currentDiagnosisSection = Boolean(secondStageAcromegaly.currentDiagnosis);

    const cardiovascularComplicationsDate =
      Boolean(secondStageAcromegaly.cardiovascularComplicationsDateStart) ||
      Boolean(secondStageAcromegaly.cardiovascularComplicationsDateEnd);

    const cardiovascularSection =
      cardiovascularComplicationsDate || Boolean(secondStageAcromegaly.anotherCardiovascularComplications);

    const carbohydrateMetabolismDisordersSection =
      Boolean(secondStageAcromegaly.carbohydrateMetabolismDisordersType) ||
      Boolean(secondStageAcromegaly.carbohydrateMetabolismDisordersDateStart) ||
      Boolean(secondStageAcromegaly.carbohydrateMetabolismDisordersDateEnd);

    const glucoseToleranceSection =
      Boolean(secondStageAcromegaly.glucoseToleranceTestGH0) ||
      Boolean(secondStageAcromegaly.glucoseToleranceTestGH60) ||
      Boolean(secondStageAcromegaly.glucoseToleranceTestGH120);

    const profileGHSection =
      Boolean(secondStageAcromegaly.profileGHHowMuch) || Boolean(secondStageAcromegaly.profileGHConcentration);

    const serumSection =
      Boolean(secondStageAcromegaly.serumPrlConcentration) ||
      Boolean(secondStageAcromegaly.serumTshConcentration) ||
      Boolean(secondStageAcromegaly.serumFt4Concentration);

    const imagingResultSinusAndDegreeSection =
      Boolean(secondStageAcromegaly.sinusInfiltraionSinus) || Boolean(secondStageAcromegaly.degreeOnTheKnospScale);

    const imagingResultOfHypothalamicPituitarySection =
      imagingResultSinusAndDegreeSection ||
      Boolean(secondStageAcromegaly.imagingResultMrTk) ||
      Boolean(secondStageAcromegaly.oppresionOfTheOpticChiasm);

    const histopathologySection =
      Boolean(secondStageAcromegaly.confirmationOfSomatotropicTumor) ||
      Boolean(secondStageAcromegaly.confirmationOfMixedTumor) ||
      Boolean(secondStageAcromegaly.proliferationMarkerKi67);

    const geneticTestSection = Boolean(secondStageAcromegaly.geneticTest);

    return (
      <Grid container justifyContent={'space-between'} width={'100%'} mt={6}>
        <Typography variant={'h4'} pb={2}>
          Dane dotyczące akromegalii
        </Typography>
        <Divider sx={{ width: '100%' }} />

        <Grid container display={'flex'} alignItems={'center'} mt={2}>
          {currentDiagnosisSection && (
            <>
              <Typography variant={'h6'}>Aktualne rozpoznanie:</Typography>
              <Grid container display={'flex'} alignItems={'center'} justifyItems={'center'}>
                <CheckedIconContainer />
                <Text>{secondStageAcromegaly.currentDiagnosis}</Text>
              </Grid>
            </>
          )}

          <Grid container display={'flex'} alignItems={'center'} mt={2}>
            <Typography variant={'h6'} mb={2} width={'100%'}>
              Dotychczasowy przebieg akromegalii:
            </Typography>
            <Grid item xs={4} pr={1}>
              <Input
                disabled
                variant={'standard'}
                fullWidth={true}
                required={true}
                name={'courseDateStart'}
                label={'Data rozpoznania'}
                type={'text'}
                maxLength={2}
                value={secondStageAcromegaly.courseDateStart || ''}
              />
            </Grid>
            <Grid item xs={4} pr={1} pl={1}>
              <Input
                disabled
                variant={'standard'}
                fullWidth={true}
                required={true}
                name={'courseWhoRecognized'}
                label={'Kto rozpoznał / specjalizacja'}
                type={'text'}
                value={secondStageAcromegaly.courseWhoRecognized}
              />
            </Grid>
            <Grid item xs={4} pr={1} pl={1}>
              <Input
                disabled
                variant={'standard'}
                fullWidth={true}
                required={true}
                name={'courseDateStartOfTreatment'}
                label={'Data rozpoczęcia leczenia'}
                type={'text'}
                value={secondStageAcromegaly.courseDateStartOfTreatment || ''}
              />
            </Grid>
          </Grid>
          {cardiovascularSection && (
            <Grid container display={'flex'} alignItems={'center'} mt={2}>
              <Typography mt={2} variant={'h6'}>
                Objawy kliniczne i powikłania w okresie ustalania rozpoznania:
              </Typography>
              {cardiovascularComplicationsDate && (
                <>
                  <Grid item xs={4} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                    <CheckedIconContainer />
                    <Text>{'powikłania sercowo-naczyniowe'}</Text>
                  </Grid>
                  <Grid item xs={4} pl={2}>
                    <Input
                      disabled
                      variant={'standard'}
                      fullWidth={true}
                      required={true}
                      name={'cardiovascularComplicationsDateStart'}
                      label={'okres trwania - OD'}
                      type={'text'}
                      maxLength={2}
                      value={getDate(secondStageAcromegaly.cardiovascularComplicationsDateStart)}
                    />
                  </Grid>
                  <Grid item xs={4} pl={2}>
                    <Input
                      disabled
                      variant={'standard'}
                      fullWidth={true}
                      required={true}
                      name={'cardiovascularComplicationsDateEnd'}
                      label={'okres trwania - DO'}
                      type={'text'}
                      value={getDate(secondStageAcromegaly.cardiovascularComplicationsDateEnd)}
                    />
                  </Grid>
                </>
              )}
              {carbohydrateMetabolismDisordersSection && (
                <>
                  <Grid item xs={4} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                    <CheckedIconContainer />
                    <Text>{'zaburzenia gospodarki węglowodanowej'}</Text>
                  </Grid>
                  <Grid item xs={4} pl={2}>
                    <Input
                      disabled
                      variant={'standard'}
                      fullWidth={true}
                      required={true}
                      name={'carbohydrateMetabolismDisordersType'}
                      label={'typ/rodzaj'}
                      type={'text'}
                      value={secondStageAcromegaly.carbohydrateMetabolismDisordersType}
                    />
                  </Grid>
                  <Grid item xs={2} pl={2}>
                    <Input
                      disabled
                      variant={'standard'}
                      fullWidth={true}
                      required={true}
                      name={'carbohydrateMetabolismDisordersDateStart'}
                      label={'okres trwania - OD'}
                      type={'text'}
                      value={getDate(secondStageAcromegaly.carbohydrateMetabolismDisordersDateStart)}
                    />
                  </Grid>
                  <Grid item xs={2} pl={2}>
                    <Input
                      disabled
                      variant={'standard'}
                      fullWidth={true}
                      required={true}
                      name={'carbohydrateMetabolismDisordersDateEnd'}
                      label={'okres trwania - DO'}
                      type={'text'}
                      value={getDate(secondStageAcromegaly.carbohydrateMetabolismDisordersDateEnd)}
                    />
                  </Grid>
                </>
              )}
              {secondStageAcromegaly.anotherCardiovascularComplications && (
                <>
                  <Grid
                    item
                    mt={1}
                    xs={6}
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyItems={'center'}
                  >
                    <CheckedIconContainer />
                    <Text>{'inne (jakie?)'}</Text>
                  </Grid>
                  <Grid item ml={4} mt={2} mb={1} xs={12}>
                    <Input
                      multiline={true}
                      disabled
                      variant={'standard'}
                      required={true}
                      name={'anotherCardiovascularComplications'}
                      type={'text'}
                      value={secondStageAcromegaly.anotherCardiovascularComplications}
                    />
                  </Grid>{' '}
                </>
              )}
            </Grid>
          )}

          <Grid container display={'flex'} alignItems={'center'} mt={2}>
            <Typography mt={2} variant={'h6'}>
              Badania hormonalne w okresie ustalania rozpoznania:
            </Typography>
            <Grid container display={'flex'} alignItems={'flex-start'}>
              <Grid item xs={5} mt={2}>
                <Input
                  multiline
                  disabled
                  variant={'standard'}
                  fullWidth={true}
                  required={true}
                  name={'growthHormoneConcentration'}
                  label={'Stężenie hormonu wzrostu (GH) w surowicy na czczo (μg/l)'}
                  type={'text'}
                  value={secondStageAcromegaly.growthHormoneConcentration}
                />
              </Grid>
              <Grid item xs={7} />

              {glucoseToleranceSection && (
                <>
                  <Grid item xs={4} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                    <CheckedIconContainer />
                    <Text>{'wykonano ocenę GH w OGTT'}</Text>
                  </Grid>
                  <Grid item xs={2.5} pl={2} alignSelf={'stretch'} mt={2}>
                    <Input
                      multiline
                      disabled
                      variant={'standard'}
                      fullWidth={true}
                      name={'glucoseToleranceTestGH0'}
                      label={"GH 0'"}
                      type={'text'}
                      value={secondStageAcromegaly.glucoseToleranceTestGH0}
                    />
                  </Grid>
                  <Grid item xs={2.5} pl={2} alignSelf={'stretch'} mt={2}>
                    <Input
                      multiline
                      disabled
                      variant={'standard'}
                      fullWidth={true}
                      name={'glucoseToleranceTestGH60'}
                      label={"GH 60'"}
                      type={'text'}
                      value={secondStageAcromegaly.glucoseToleranceTestGH60}
                    />
                  </Grid>
                  <Grid item xs={2.5} pl={2} alignSelf={'stretch'} mt={2}>
                    <Input
                      multiline
                      disabled
                      variant={'standard'}
                      fullWidth={true}
                      name={'glucoseToleranceTestGH120'}
                      label={"GH 120'"}
                      type={'text'}
                      value={secondStageAcromegaly.glucoseToleranceTestGH120}
                    />
                  </Grid>
                </>
              )}
              {profileGHSection && (
                <>
                  <Grid item xs={4} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                    <CheckedIconContainer />
                    <Text>{'wykonano profil GH'}</Text>
                  </Grid>
                  <Grid item xs={4} pl={2} alignSelf={'stretch'} mt={2}>
                    <Input
                      multiline
                      disabled
                      variant={'standard'}
                      fullWidth={true}
                      name={'profileGHHowMuch'}
                      label={'Liczba oznaczeń GH w profilu'}
                      type={'text'}
                      value={secondStageAcromegaly.profileGHHowMuch}
                    />
                  </Grid>
                  <Grid item xs={4} pl={2} alignSelf={'stretch'} mt={2}>
                    <Input
                      multiline
                      disabled
                      variant={'standard'}
                      fullWidth={true}
                      name={'profileGHConcentration'}
                      label={'Średnie stężenie GH w μg/l'}
                      type={'text'}
                      value={secondStageAcromegaly.profileGHConcentration}
                    />
                  </Grid>
                </>
              )}
              <Grid item display={'flex'} xs={12} mt={1} mr={1}>
                <Text>Oznaczono stężenie IGF-1</Text>
              </Grid>
              <Grid item xs={6} alignSelf={'stretch'}>
                <Input
                  multiline
                  disabled
                  variant={'standard'}
                  required={true}
                  name={'serumIGF1Concentration'}
                  label={'Stężenie IGF-1 w surowicy w wartościach bezwzględnych (μg/l)'}
                  type={'text'}
                  value={secondStageAcromegaly.serumIGF1Concentration}
                />
              </Grid>
              <Grid item display={'flex'} xs={6} pl={2} alignSelf={'stretch'}>
                <Input
                  multiline
                  disabled
                  variant={'standard'}
                  required={true}
                  name={'uln'}
                  label={'ULN'}
                  type={'text'}
                  value={secondStageAcromegaly.uln}
                />
              </Grid>
              {serumSection && (
                <Grid item display={'flex'} xs={12} mt={2} gap={1} alignSelf={'stretch'}>
                  <Input
                    multiline
                    disabled
                    variant={'standard'}
                    required={true}
                    name={'serumPrlConcentration'}
                    label={'stężenie PRL surowicy (μg/l)'}
                    type={'text'}
                    value={secondStageAcromegaly.serumPrlConcentration}
                  />
                  <Input
                    multiline
                    disabled
                    variant={'standard'}
                    required={true}
                    name={'serumTshConcentration'}
                    label={'stężenie TSH w surowicy (mIU/l)'}
                    type={'text'}
                    value={secondStageAcromegaly.serumTshConcentration}
                  />
                  <Input
                    multiline
                    disabled
                    variant={'standard'}
                    required={true}
                    name={'serumFt4Concentration'}
                    label={'stężenie fT4 w surowicy (pmol/l)'}
                    type={'text'}
                    value={secondStageAcromegaly.serumFt4Concentration}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {imagingResultOfHypothalamicPituitarySection && (
            <>
              <Grid container display={'flex'} alignItems={'center'} mt={2}>
                <Typography mt={2} variant={'h6'}>
                  Wynik obrazowania układu podwzgórzowo-przysadkowego w MR/TK:
                </Typography>
                <Grid container alignItems={'center'}>
                  {secondStageAcromegaly.imagingResultMrTk && (
                    <>
                      <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                        <CheckedIconContainer />
                        <Text>{secondStageAcromegaly.imagingResultMrTk}</Text>
                      </Grid>
                    </>
                  )}
                  {secondStageAcromegaly.oppresionOfTheOpticChiasm && (
                    <>
                      <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                        <CheckedIconContainer />
                        <Text>{secondStageAcromegaly.oppresionOfTheOpticChiasm}</Text>
                      </Grid>
                    </>
                  )}
                  {imagingResultSinusAndDegreeSection && (
                    <>
                      <Grid item xs={4} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                        <CheckedIconContainer />
                        <Text>{'naciekanie zatok jamistych'}</Text>
                      </Grid>
                      <Grid item xs={4} pl={2}>
                        <Input
                          multiline
                          disabled
                          variant={'standard'}
                          fullWidth={true}
                          name={'sinusInfiltraionSinus'}
                          label={'zatoka'}
                          type={'text'}
                          value={secondStageAcromegaly.sinusInfiltraionSinus}
                        />
                      </Grid>
                      <Grid item xs={4} pl={2}>
                        <Input
                          multiline
                          disabled
                          variant={'standard'}
                          fullWidth={true}
                          name={'degreeOnTheKnospScale'}
                          label={'stopień w skali Knospa'}
                          type={'text'}
                          value={secondStageAcromegaly.degreeOnTheKnospScale}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          )}

          {histopathologySection && (
            <>
              <Grid container display={'flex'} alignItems={'center'} mt={2}>
                <Typography mt={2} variant={'h6'}>
                  Badanie histopatologiczne:
                </Typography>
                <Grid container display={'flex'} alignItems={'center'}>
                  {secondStageAcromegaly.confirmationOfSomatotropicTumor && (
                    <>
                      <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'} mt={2}>
                        <CheckedIconContainer />
                        <Text>{secondStageAcromegaly.confirmationOfSomatotropicTumor}</Text>
                      </Grid>
                    </>
                  )}
                  {secondStageAcromegaly.confirmationOfMixedTumor && (
                    <>
                      <Grid item xs={12} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                        <CheckedIconContainer />
                        <Text>{secondStageAcromegaly.confirmationOfMixedTumor}</Text>
                      </Grid>
                    </>
                  )}
                  {secondStageAcromegaly.proliferationMarkerKi67 && (
                    <>
                      <Grid item xs={3}>
                        <Text>Marker proliferacji Ki-67</Text>
                      </Grid>
                      <Grid item xs={9} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                        <CheckedIconContainer />
                        <Text>{secondStageAcromegaly.proliferationMarkerKi67}</Text>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          )}

          {geneticTestSection && (
            <Grid container display={'flex'} alignItems={'center'} mt={2}>
              <Typography variant={'h6'} mb={2} width={'100%'}>
                Badanie genetyczne:
              </Typography>
              <Grid item xs={12} pr={1}>
                <Input
                  multiline
                  disabled
                  variant={'standard'}
                  fullWidth={true}
                  required={true}
                  name={'geneticTest'}
                  type={'text'}
                  value={secondStageAcromegaly.geneticTest}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
  return <></>;
});
